import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CurrencyService } from '@app/currency/services/currency.service';
import { LanguageService } from '@app/i18n';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-currency-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyDropdownComponent {
    @Output() submitEvent = new EventEmitter<true>();

    form = new FormGroup({
        currency: new FormControl(this.currencyService.get()),
        language: new FormControl(this.languageService.get()),
    });

    currencies = environment.currency.available.map((currency) => ({
        name: currency,
        value: currency,
    }));

    lanugages = environment.supportedLanguages.map((language) => ({
        name: <string>this.translateService.instant(`language.${language.key}`),
        value: language.key,
    }));

    constructor(private currencyService: CurrencyService, private languageService: LanguageService, private translateService: TranslateService) {}

    onSubmit() {
        this.currencyService.set(<string>this.form.get('currency')?.value);
        this.languageService.set(<string>this.form.get('language')?.value);
        this.submitEvent.next(true);
    }
}
